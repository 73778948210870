import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const SendMailPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})



  return (
    <Layout location={location}>
      <Seo title={t('users.sendmail.seo.title')} description={t('users.sendmail.seo.description')} />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div className={Styles.users}>
              <h1>{t('users.sendmail.title')}</h1>
              <section>
                <div className={Styles.mg_b2}>
                  {t('users.sendmail.message1')}<br/>
                  {t('users.sendmail.message2')}<br/>
                  <br/>
                  {t('users.sendmail.message3')}<br/>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default SendMailPage;
